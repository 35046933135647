@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.service-percent{
    width: 50%;
}
 
 .service-orange{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
 }

 .service-white{
    border-top-left-radius: 20px;
 
 }
 .footer-color{
   background-color: #114B5F;
 }
 .grayish{
   color:#114B5F ;
 }

 html {
  scroll-behavior: smooth;
}
.menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  transition: left 0.3s ease-in-out;
  overflow: hidden;
  z-index: 1000;
  
}
.mobile-grayish{
  background-color: #114B5F ;
}

.list-border{
  border-bottom: 0.5px  solid #114B5F;
  border-top: 0.5px  solid #114B5F;
}
.list-border-bottom{
  border-bottom: 0.5px  solid #114B5F;
   
}

.menu.open {
  left: 0;
}

.menu ul {
  list-style-type: none;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu ul li {
  padding: 16px;
  text-align: center;
}

.menu ul li a {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: center;
}
 
